import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StatLabel,
  Text,
} from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useAsyncFn } from 'react-use';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_term = '(КУПОН 7000)';
const utm_campaign = '(КУПОН 7000)';
const budget = 1990;

const getQueryParams = () => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  console.log(window.location);
  const keys = Array.from(searchParams.keys());
  return keys.map(x => ({ [x]: searchParams.get(x) }));
};

export const Home = observer(() => {
  console.log(getQueryParams());
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [city, setCity] = useState<string>('Казань');

  const [promocode, setPromocode] = useState<string>('');
  const [formEnable, setFormEnable] = useState<boolean>(true); // ! Включение промокода

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'small-zone');
  }, []);

  //* ==================
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        budget,
        city,
        name: username,
        phone: '+7' + phone,
        utm_term,
        utm_source: window.location.hostname,
        utm_campaign,
      }).then(async () => {
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  const [status, checkPromocode] = useAsyncFn(async () => {
    return new Promise(x => {
      setTimeout(() => {
        if (promocode === 'КРАСОТКА987') {
          setFormEnable(true);
        } else {
          setErrorText('Вы ввели неверный промокод');
          setError(true);
        }
        x(true);
      }, 2000);
    });
  }, [formEnable, promocode]);

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <Text style={{ textAlign: 'center', fontSize: 14 }}>
          <br />
          🥳Поздравляем! <br />
          🎁Вы как клиент <b>[Soul Unit]</b> получаете шикарный подарок, который позволит добиться не только идеального
          тела, но ментального здоровья! Осознанный подарок для идеальной версии себя! <br /> <br />
          😇Сеть клиник "Миссис Лазер" дарит Вам купон на сумму 7000 Р <br />
          Который Вы можете потратить на услуги лазерной эпиляции, которые подарят Вам гладкое тело, комфорт и
          необходимую каждому - уверенность в себе! <br /> <br />
          Для того чтобы получить подарок, укажите пожалуйста Ваши имя и телефон!
          <br />
        </Text>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            {formEnable ? (
              <>
                <InputGroup>
                  <Input
                    maxLength={30}
                    /* isInvalid={error} */
                    placeholder='Введите ваше имя'
                    value={username}
                    onChange={e => {
                      setUserName(e.target.value);
                      setError(false);
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children='+7' />
                  <Input
                    maxLength={10}
                    type='tel'
                    /* isInvalid={error} */
                    placeholder='Введите ваш телефон'
                    value={phone}
                    onChange={event => {
                      isNumeric(event.target.value) && setPhone(event?.target.value);
                      setError(false);
                    }}
                  />
                </InputGroup>
              </>
            ) : (
              <InputGroup>
                <Input
                  maxLength={30}
                  /* isInvalid={error} */
                  placeholder='Введите промокод'
                  value={promocode}
                  onChange={e => {
                    setPromocode(e.target.value.toUpperCase());
                    setError(false);
                  }}
                />
              </InputGroup>
            )}

            {/*  <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Казань'>Казань</option>
              </Select>
            </InputGroup> */}
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}
          <Button
            /* style={{ marginBottom: 16 }} */
            isLoading={status.loading}
            bgColor={formEnable ? undefined : 'blue.600'}
            onClick={() => {
              if (formEnable) {
                if (city === '' || phone === '' || username === '') {
                  setError(true);
                  setErrorText(
                    city === ''
                      ? 'Вы не указали город'
                      : phone === ''
                      ? 'Вы не указали свой номер'
                      : username === ''
                      ? 'Вы не указали своё имя'
                      : null
                  );
                } else {
                  onSubmit();
                }
              } else {
                checkPromocode();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
            style={{ marginBottom: 16 }}
          >
            {formEnable ? 'Получить подарок' : 'Проверить'}
          </Button>
          {/* <Typography.Paragraph
            style={{ textAlign: 'center', marginBottom: 16, marginTop: 8, textAlignLast: 'left', fontSize: 12 }}
          >
            * Акция действует только для клиники в г. Казань
          </Typography.Paragraph> */}
        </Stack>
      </Box>
    </AppTemplate>
  );
});
