import React from 'react';
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from '@chakra-ui/layout';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Result, Typography } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Routes } from '../../shared/enums/routes';
import { Link } from 'react-router-dom';
import { FaTelegram, FaVk } from 'react-icons/fa';

export const SuccessPage = () => {
  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'small-zone_success_appointment');
  }, []);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Успешно'
        extra={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography.Title style={{ fontSize: 14, marginTop: 0 }}>
              Спасибо за заявку, позже мы с Вами свяжемся
            </Typography.Title>
            <Typography.Paragraph>
              Подпишитесь на наши социальные сети, чтобы быть в курсе событий и получать полезный контент!
            </Typography.Paragraph>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '180px',
                alignSelf: 'center',
                marginBottom: '24px',
              }}
            >
              <a href='https://t.me/missislaser'>
                <FaTelegram size={28} color='#0088cc' />
              </a>
              <a href='https://vk.com/missis_laser'>
                <FaVk size={28} color='#0077ff' />
              </a>
              <a href='https://www.instagram.com/missis_laser/'>
                <img alt='inst' src='inst.png' style={{ width: '24px' }} />
              </a>
              <a href='https://dzen.ru/missis_laser'>
                <img alt='dzen' src='dzen.png' style={{ width: '24px' }} />
              </a>
            </div>
            <div style={{ marginTop: 16 }}>
              <Link to={`${Routes.Preparation}`}>
                <ChakraButton size='sm' colorScheme='green' /* leftIcon={<FaInstagram />} */>
                  Как подготовится к процедуре?
                </ChakraButton>
              </Link>
            </div>
          </div>
        }
      />
    </Box>
  );
};
