import { TUser } from '../../types/user.type';
import { instance, instanceBackend, instanceVakas } from './base';
import { TResponse } from './types';

type TVerifySMSCode = {
  companyId: number;
  phone: string;
  fullname?: string;
};

export const verifySMSCode = async ({ companyId, ...body }: TVerifySMSCode) => {
  return await instance.post<TResponse>(`book_code/${companyId}`, { ...body });
};

type TAuthByCode = {
  phone: string;
  code: string;
};

export const authByCode = async ({ phone, code }: TAuthByCode) => {
  return await instance.post<TResponse<TUser>>(`user/auth`, { phone, code });
};

type TAuthByCode1 = {
  phone: string;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const sendPhone = async ({ phone }: TAuthByCode1) => {
  return await instanceBackend.post<TResponse<TUser>>(`user`, {
    username: 'MissisLaser' + getRandomInt(999999999999999),
    password: 'xxxxxxx',
    sex: 2,
    phone: '7' + phone,
  });
};

type CheckSeancesByPhone = {
  phone: string;
  clinic_id: number;
};

export const checkPhone = async ({ phone, clinic_id }: CheckSeancesByPhone) => {
  return await instanceBackend.post<{ success: boolean; count: number }>(`user/check-seances`, {
    clinic_id,
    phone: '7' + phone,
  });
};

export const sendLeadToVakas = async (data: {
  utm_term: string;
  phone: string;
  name: string;
  city: string;
  utm_source: string;
  utm_campaign: string;
  budget: number;
}) => {
  return await instanceVakas.post(``, JSON.stringify(data));
};
